.foot {
  display: flex;
  justify-content: flex-end;
  color: white;
  margin-right: 2em;
}

.mail-to {
  cursor: pointer;
}

#marco {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
}

#gato {
  background: url("https://res.cloudinary.com/pastelitos/image/upload/v1610526571/eva/gatito_pushui.svg");
  height: 20em;
  width: 30em;
  -webkit-animation: sprite-animation 1.2s steps(16, end) infinite;
  -moz-animation: sprite-animation 1.2s steps(16, end) infinite;
  -ms-animation: sprite-animation 1.2s steps(16, end) infinite;
  animation: sprite-animation 1.2s steps(16, end) infinite;
}

@-webkit-keyframes sprite-animation {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -8120px 0;
  }
}

@-ms-keyframes sprite-animation {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -8120px 0;
  }
}

@-moz-keyframes sprite-animation {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -8120px 0;
  }
}

@keyframes sprite-animation {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -8120px 0;
  }
}

@media (max-width: 449px) {
  #marco {
    display: none;
  }
  .cat-typing {
    display: none;
  }
}

.cat-typing {
  margin-right: 4em;
  box-shadow: 0px 0px 2em 2em #fcf0e4;
  overflow: hidden;
  height: 11em;
  background-color: #fcf0e4;
}
.cat-typing > img {
  height: 11em;
}

.text-trans {
  display: flex;
  gap: 1em;
  justify-content: space-evenly;
}

.trans-txtarea {
  min-width: 100%;
  max-width: 100%;
  border: 0.6px solid lightgray;
  border-radius: 2px;
  margin-top: 10px;
  padding: 1em;
  font-size: 1.1rem;
  color: rgb(58, 55, 55);
  min-height: 160px;
}

.landing-parent {
  height: 81vh;
  overflow: scroll;
}

.partition {
  height: 100%;
}

.login-action-btn-wrapper {
  display: flex;
  justify-content: center;
  gap: 1em;
  align-items: center;
  flex-wrap: wrap;
}

.page-title {
  text-align: center;
}

.landing-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2em;
  height: 73vh;
  justify-content: space-between;
}
.button-content {
  display: flex;
  gap: 1em;
  width: 15em;
  justify-content: center;
}

.col {
  display: flex;
  align-items: center;
  height: 4em;
}

.code-icon-wrapper {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  cursor: pointer;
}
.code-icon-link {
  color: black;
}
.floating-cat {
  right: 0;
  margin-top: -11em;
  display: flex;
  justify-content: flex-end;
}

.flex-end {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

@media (max-width: 449px) {
  .flex-end {
    flex-direction: column-reverse;
  }
}

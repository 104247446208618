.card-container {
  display: flex;
  flex-direction: column;
  gap: 2em;
  justify-content: center;
  width: fit-content;
  margin: 2em auto;
}
.select-with-add-btn {
  display: flex;
}
